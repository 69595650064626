.App {
  background-color: #1f2d47;
  color: white;
  text-align: center;
  min-height: 100vh;
  display: flex;  
  flex-direction: column;
}

.Profiles {
  min-height: 95vh;
  display: flex;  
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.Footer-main {
  font-size: smaller;
  margin-bottom: 0;
}
.Profile {
  display: inline-block;
  width: 30%
}

.Image {
  border: 2px solid #ddd;
  border-radius: 20%;
  width: 33%;
}

.Name {
  font-weight: bold;
  font-size: larger;
}

.Description {
  font-size: large;
}

.Link {
  font-size: medium;
  color: #0087c5;
}